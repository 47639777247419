import {
  featherFile,
  featherChevronRight,
  featherFolder,
  featherImage,
  featherMoreVertical,
  featherCoffee,
  featherArrowLeft,
  featherFileText,
  featherChevronLeft,
  featherUploadCloud,
  featherTrendingUp,
  featherExternalLink,
  featherDownload,
  featherCreditCard,
  featherClipboard,
  featherShuffle,
  featherUsers,
  featherTruck,
  featherBookmark,
  featherShoppingBag,
  featherPrinter,
  featherPackage,
  featherEye,
  featherX,
  featherXCircle,
  featherTool,
  featherUserCheck,
  featherBox,
  featherChevronDown,
  featherChevronsDown,
  featherLogOut,
  featherAirplay,
  featherSettings,
  featherBell,
  featherSearch,
  featherAlertCircle,
  featherCalendar,
  featherArchive,
  featherEdit3,
  featherTrash,
  featherEdit,
  featherTrash2,
  featherCircle,
  featherUser,
  featherThumbsUp,
  featherThumbsDown,
  featherClock,
  featherNavigation,
  featherCheckCircle,
  featherRefreshCcw,
  featherList,
  featherGitMerge,
  featherSliders,
  featherFilter,
  featherPlusSquare,
  featherPlusCircle,
  featherSend,
  featherLock,
  featherArrowRightCircle,
  featherArrowLeftCircle,
  featherUnlock,
  featherUserX,
  featherEyeOff,
  featherDatabase,
  featherPocket,
  featherAward,
  featherUserPlus,
  featherShoppingCart,
  featherDollarSign,
  featherAlignRight,
  featherArrowRight,
  featherPlay,
  featherChevronUp,
  featherHelpCircle,
  featherFolderPlus,
  featherFolderMinus,
  featherFilePlus,
  featherLayers,
  featherCornerDownRight,
  featherCrosshair,
  featherMinusSquare,
  featherRotateCcw,
  featherMaximize,
  featherStar,
  featherPlus,
  featherMapPin,
  featherMail,
  featherPhone,
  featherInfo,
  featherDownloadCloud
} from "@ng-icons/feather-icons";

export default {
  featherPhone,
  featherMail,
  featherStar,
  featherPlus,
  featherMapPin,
  featherCornerDownRight,
  featherLayers,
  featherFilePlus,
  featherFolderPlus,
  featherFolderMinus,
  featherHelpCircle,
  featherFile,
  featherChevronRight,
  featherFolder,
  featherImage,
  featherMoreVertical,
  featherCoffee,
  featherArrowLeft,
  featherFileText,
  featherChevronLeft,
  featherUploadCloud,
  featherTrendingUp,
  featherExternalLink,
  featherDownload,
  featherCreditCard,
  featherClipboard,
  featherShuffle,
  featherUsers,
  featherTruck,
  featherBookmark,
  featherShoppingBag,
  featherPrinter,
  featherPackage,
  featherEye,
  featherX,
  featherXCircle,
  featherTool,
  featherUserCheck,
  featherBox,
  featherChevronDown,
  featherChevronsDown,
  featherLogOut,
  featherAirplay,
  featherSettings,
  featherBell,
  featherSearch,
  featherAlertCircle,
  featherCalendar,
  featherArchive,
  featherEdit3,
  featherTrash,
  featherEdit,
  featherTrash2,
  featherSliders,
  featherCircle,
  featherUser,
  featherThumbsUp,
  featherThumbsDown,
  featherClock,
  featherNavigation,
  featherCheckCircle,
  featherRefreshCcw,
  featherList,
  featherGitMerge,
  featherFilter,
  featherPlusSquare,
  featherPlusCircle,
  featherSend,
  featherLock,
  featherArrowRightCircle,
  featherArrowLeftCircle,
  featherUnlock,
  featherUserX,
  featherEyeOff,
  featherDatabase,
  featherPocket,
  featherAward,
  featherUserPlus,
  featherShoppingCart,
  featherDollarSign,
  featherAlignRight,
  featherArrowRight,
  featherPlay,
  featherChevronUp,
  featherCrosshair,
  featherMinusSquare,
  featherRotateCcw,
  featherMaximize,
  featherInfo,
  featherDownloadCloud
};