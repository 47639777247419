    <div  class="row my-1 cursor p-3">
      <div class="col-5">
        <div class="d-flex align-items-center">
          <div>
            <div class="date-card" [ngClass]="transaction.dateCardClass">
              <div class="date-card-header">
                {{ transaction.date | date : "dd MMM" }}
              </div>
              <div class="date-card-body" [ngClass]="transaction.dateCardClass">
                <span>
                  <img
                    [src]="transaction.dateCardImg"
                  />
                </span>
              </div>
            </div>
        </div>

        <div style="margin-left: 16px;">
          <div class="f-14">
            {{
              transaction.nameTitle
                | truncate : "30"
            }}
          </div>
          <div class="f-transaction-status mt-1">

            <span>{{transaction.paymentType}}</span>
          </div>
        </div>

        </div>
      </div>
      <div class="col-4  d-flex flex-column justify-content-center">
        <div *ngIf="fromPage === 'company'" class="f-14">
          <span>{{transaction?.project_name}}</span>
        </div>
        <div class="text-type-subtitle">
          <span>{{transaction?.remark}}</span>
        </div>
        <div class="d-none d-sm-block" *ngIf="fromPage !== 'company'">
          <span class="table-btn ">

            {{transaction?.paymentTypeStatus}}
          </span>
        </div>
      </div>
      <div class="col-3 d-flex align-items-center justify-content-end">
        <div style="text-align: right;" class="d-flex flex-column">
        <div style=" min-width:80px">
          <span  class="f-14">{{transaction?.amount | dynamicNumber: {currency:1} }}</span>
        </div>
        <div *ngIf="transaction.paymentType != 'Material Return' && transaction.paymentType != 'Site Expense' && transaction.paymentType  != 'Transfer In' && transaction.paymentType  != 'Transfer Out' && transaction.paymentType != 'Debit Note' && transaction.paymentType != 'Sales Invoice'">
          <span class="table-btn f-transaction-status">
            {{transaction?.paymentStatus || '-'}}
          </span>
        </div>
        </div>
      </div>
    </div>