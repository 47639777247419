import { HttpParams } from '@angular/common/http';
import { Component, Input, signal } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { INVOICE_FEATURE_TYPE } from 'src/app/enum/invoice_feature_details';
import { FEATURE_TYPE } from 'src/app/enum/transaction_feature_enums';
import { Address, ShipToBillToAddress } from 'src/app/interfaces/interfaces';


interface Parent{
  company_id?: string;
  project_id?: string;
  party_company_user_id?: string;
  fromPage?: FEATURE_TYPE.PO | INVOICE_FEATURE_TYPE.CUSTOMER_INVOICE | INVOICE_FEATURE_TYPE.MATERIAL_SALE | INVOICE_FEATURE_TYPE.PARTY_EARNING | INVOICE_FEATURE_TYPE.MATERIAL | INVOICE_FEATURE_TYPE.SUBCON_EXPENSE;
  shipToBillToAddress?: ShipToBillToAddress;
}
interface AddressObj{
  addressList?: Address[];
  valueStack?: {count: number, next_url: string};
}
interface AddressListObj{
  company?: AddressObj;
  project?: AddressObj;
  person?: AddressObj;
}

@Component({
  selector: 'app-ship-to-bill-to',
  templateUrl: './ship-to-bill-to.component.html',
  styleUrls: ['./ship-to-bill-to.component.css']
})
export class ShipToBillToComponent {
  @Input() parent = {} as Parent;
  listTypes = ['company', 'project','person'];
  shipToBillToAddress = signal<ShipToBillToAddress>({} as ShipToBillToAddress);
  addressListObj = {} as AddressListObj;
  public InvoiceFeatureType = INVOICE_FEATURE_TYPE;
  public FEATURE_TYPE = FEATURE_TYPE;
  constructor(
    public activeModal: NgbActiveModal,
    private adminService: AdminService,
    private authService: AuthService,
    private notificationService: NotificationsService,
  ) { }
  ngOnInit(): void {
    this.getAddressLists();
    if(this.parent.shipToBillToAddress){
      this.shipToBillToAddress.set(this.parent.shipToBillToAddress);
      if (this.shipToBillToAddress().bill_from_address_id && this.shipToBillToAddress().bill_from_address_id == this.shipToBillToAddress().ship_from_address_id) {
        this.shipToBillToAddress().isShipFromSame = true;
      }
      if (this.shipToBillToAddress().bill_to_address_id && this.shipToBillToAddress().bill_to_address_id == this.shipToBillToAddress().ship_to_address_id) {
        this.shipToBillToAddress().isShipToSame = true;
      }
    }
  }
  getAddressLists() {
    this.listTypes.forEach(type => {
      let qp = new HttpParams();
      qp = qp.set('company_id', this.parent.company_id);
      qp = qp.set('address_type', type);
      switch (type) {
        case 'company':
          qp = qp.set('owner_id', this.parent.company_id);
          break;
        case 'project':
          qp = qp.set('owner_id', this.parent.project_id);
          break;
        case 'person':
          qp = qp.set('owner_id', this.parent.party_company_user_id);
          break;
      }
      this.adminService.getAddressListByType(qp).subscribe(res => {
        this.addressListObj[type] = {
          addressList: res.address_list || [] as Address[],
          valueStack: res.page,
        } as AddressObj;
      });
    });
  }
  handleCheckboxChange(isChecked, child: 'ship-from' | 'ship-to') {
    if (isChecked) {
      if (child === 'ship-from') {
        if (this.shipToBillToAddress().bill_from_address_id) {
          this.shipToBillToAddress().ship_from_address_id = this.shipToBillToAddress().bill_from_address_id;
          this.shipToBillToAddress().monkey_patch_ship_from_address = this.shipToBillToAddress().monkey_patch_bill_from_address;
        } else {
          this.shipToBillToAddress().isShipFromSame = true;
        }
      } else if (child === 'ship-to') {
        if (this.shipToBillToAddress().bill_to_address_id) {
          this.shipToBillToAddress().ship_to_address_id = this.shipToBillToAddress().bill_to_address_id;
          this.shipToBillToAddress().monkey_patch_ship_to_address = this.shipToBillToAddress().monkey_patch_bill_to_address;
        } else {
          this.shipToBillToAddress().isShipToSame = true;
        }
      }
    } else {
      if (child === 'ship-from') {
        this.shipToBillToAddress().ship_from_address_id = null;
        this.shipToBillToAddress().monkey_patch_ship_from_address = null;
        this.shipToBillToAddress().isShipFromSame = false;
      } else if (child === 'ship-to') {
        this.shipToBillToAddress().ship_to_address_id = null;
        this.shipToBillToAddress().monkey_patch_ship_to_address = null;
        this.shipToBillToAddress().isShipToSame = false;
      }
    }
  }
  handleAddressSelect(address: Address, child: 'bill-from' | 'bill-to' | 'ship-from' | 'ship-to') {
    if (child === 'bill-from') {
      this.shipToBillToAddress().bill_from_address_id = address.id;
      this.shipToBillToAddress().monkey_patch_bill_from_address = address;
      if (this.shipToBillToAddress().isShipFromSame) {
        this.shipToBillToAddress().ship_from_address_id = address.id;
        this.shipToBillToAddress().monkey_patch_ship_from_address = address;
      }
    } else if (child === 'bill-to') {
      this.shipToBillToAddress().bill_to_address_id = address.id;
      this.shipToBillToAddress().monkey_patch_bill_to_address = address;
    } else if (child === 'ship-from') {
      this.shipToBillToAddress().ship_from_address_id = address.id;
      this.shipToBillToAddress().monkey_patch_ship_from_address = address;
    } else if (child === 'ship-to') {
      this.shipToBillToAddress().ship_to_address_id = address.id;
      this.shipToBillToAddress().monkey_patch_ship_to_address = address;
    }
  }

  save() {
    if(!this.shipToBillToAddress().bill_from_address_id && !this.shipToBillToAddress().bill_to_address_id 
      && !this.shipToBillToAddress().ship_from_address_id && !this.shipToBillToAddress().ship_to_address_id) {
        this.notificationService.initiate({ title: "Please select at least one address", type: toastTypes.error });
        return;
      }
    this.activeModal.close(this.shipToBillToAddress());
  }
}
