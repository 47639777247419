<div class="canvas-height">
  <app-logo-loader [state]="loading"></app-logo-loader>
  <div class="offcanvas-header d-flex align-items-center px-4">
    <div class="canvas-header-left d-flex align-items-center gap-4">
      <app-back-button></app-back-button>
      <div class="canvas-title d-flex flex-wrap flex-column">
        <span class="f-14">ADD PARTY</span>
      </div>
    </div>
    <div class="d-flex gap-4 align-items-center">
      <div class="canvas-cancel-btn" (click)="cancelBtn()">Cancel</div>
      <div class="canvas-save-btn" (click)="saveBtn()">Save</div>
    </div>
  </div>
  <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>

  <div class="canvas-body d-flex flex-column gap-3">
    <div class="custom-input">
      <label for="party">Party</label>
      <input id="party" name="name" [ngClass]="formInputError == 'name' ? 'input-error' : ''" (keyup)="trimString()"
        placeholder="Party Name" class="search_input" [(ngModel)]="companyUser.name" />
    </div>
    <div class="d-flex gap-3">
      <div ngbDropdown class="form-control input-height w-25 px-1 d-flex justify-content-center align-items-center">
        <div ngbDropdownToggle class="d-flex justify-content-center align-items-center gap-2"
          (click)="selectingCountry?selectingCountry=false:selectingCountry=true">
          <div class="align-self-center"><img [src]="country.flag" width="15" alt=""></div>
          <div class="fs-8 align-self-center">+{{country.country_code}}</div>
          <div class="align-self-center">
            <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.36603 7.5C4.98112 8.16667 4.01887 8.16667 3.63397 7.5L0.602886 2.25C0.217986 1.58333 0.699111 0.75 1.46891 0.75L7.53109 0.750001C8.30089 0.750001 8.78202 1.58333 8.39711 2.25L5.36603 7.5Z"
                fill="#1A0C52" />
            </svg>
          </div>
        </div>
        <div ngbDropdownMenu class="custom-menu">
          <div ngbDropdownItem class="d-flex justify-content-between p-2 gap-3 pointer dropdown-list"
            [disabled]="companyUser.id && company.creator == companyUser.user_id"
            (click)="setCountry(item)" *ngFor="let item of countries">
            <div class="align-self-center w-10"><img [src]="item.flag" loading="lazy" width="20" alt=""></div>
            <div class="align-self-center text-right flex-grow-1 fs-8">{{item.country_name}}</div>
            <div class="align-self-center fs-8">+{{item.country_code}}</div>
          </div>
        </div>
      </div>
      <div class="custom-input w-75">
        <label for="mobile">Phone</label>
        <input id="mobile" name="mobile" [ngClass]="formInputError == 'mobile' ? 'input-error' : ''"
          (keyup)="numberValidate($event)" type="text" placeholder="Phone" class="search_input"
          [(ngModel)]="mobileString" #mobile [disabled]="companyUser.id && company.creator == companyUser.user_id" />
      </div>
    </div>
    <div ngbDropdown style="border-radius: 8px; height: 52px;" class="border cursor w-100 ">
      <div ngbDropdownToggle class="w-100 h-100 d-flex justify-content-between align-items-center px-3">
        <span class="f-14 disabled-font">{{selectedPartyType?selectedPartyType:'Select Party Type'}}</span>
        <div>
          <ng-icon style="color: #898da3" name="featherChevronDown"></ng-icon>
        </div>
      </div>
      <div ngbDropdownMenu class="w-100 px-3">
        <div class="py-2 d-flex justify-content-start align-items-center border-bottom">
          <span class="f-14">Party Type</span>
        </div>
        <div style="padding-top: 8px;" class="w-100 d-flex flex-column gap-2">
          <ng-container class="d-flex flex-column" *ngFor="let item of partyTypesClient">
            <ng-container *ngIf="!item.is_vendor">
              <div ngbDropdownItem (click)="setCompanyUserType(item)" class="px-2 py-1 party-type-tile">
                <span class="f-14 disabled-font">{{item.name}}</span>
                <input type="radio" [checked]="selectedPartyType === item.name">
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="py-2 d-flex justify-content-start align-items-center border-bottom">
          <span class="f-14">Vendor</span>
        </div>
        <div style="padding-top: 8px;" class="w-100 d-flex flex-column gap-2">
          <ng-container class="d-flex flex-column" *ngFor="let item of partyTypesClient">
            <ng-container *ngIf="item.is_vendor">
              <div ngbDropdownItem (click)="setCompanyUserType(item)" class="px-2 py-1 party-type-tile">
                <span class="f-14 disabled-font">{{item.name}}</span>
                <input type="radio" [checked]="selectedPartyType === item.name">
              </div>
            </ng-container>
          </ng-container>
        </div>

      </div>
    </div>
    <!-- <div class="custom-div w-100">
    <label for="party">Party Type</label>
   <div ngbDropdown style="width: 100%;" >
   <div ngbDropdownToggle class="border cursor">
    <span  class="f-14 cursor w-100">Labour</span>
   </div>
    <div ngbDropdownMenu class="custom-menu w-100">

    </div>
   </div>
  </div> -->
    <!-- <div *ngIf="companyUser.type === 'customer'||companyUser.type === 'vendor'" class="d-flex justify-content-between gap-5">
      <div
        (click)=" companyUser.type = 'customer'"
        class="custom-div d-flex justify-content-center gap-2 custom-radio"
      >
        <div
          class="radio-selector selected-radio"
          [ngClass]="{ 'selected-radio': companyUser.type === 'customer'}"
        ></div>
        <span class="f-14"> Client </span>
      </div>
      <div
        (click)=" companyUser.type = 'vendor' "
        class="custom-div d-flex justify-content-center gap-2 custom-radio"
      >
        <div
          class="radio-selector"
          [ngClass]="{ 'selected-radio': companyUser.type === 'vendor'}"
        ></div>
        <span class="f-14"> Vendor </span>
      </div>
    </div> -->

    <div *ngIf="selectedPartyType !== 'Staff' && selectedPartyType !== 'Labour' && selectedPartyType"
      (click)="openGstDetails()" class="custom-div gst-deets">
      <div class="d-flex gap-2">
        <ng-icon style="color: #898da3; margin-inline: 8px" name="featherFile"></ng-icon>
        <div class="d-flex flex-column">
          <span
            class="f-12 disabled-font">{{authService.session.country?.tax_display_name?authService.session.country?.tax_display_name:'Tax'}}</span>
          <span class="f-14">{{companyUser?.gstin?companyUser?.gstin:'--Na--'}}</span>
        </div>
      </div>

      <div class="d-flex align-items-center gap-2">
        <span class="f-14 disabled-font"> {{ companyUser?.state_of_supply?companyUser?.state_of_supply:'--NA--'}}
        </span>
        <div class="icon-box">
          <ng-icon style="color: #898da3; margin-inline: 8px" name="featherChevronRight"></ng-icon>
        </div>
      </div>
    </div>



    <!-- <div *ngIf="!gstDetails" class="txt-btn-wrap">
      <button (click)="gstDetails = true" class="text-btn f-12 disabled-font">+ GST Details</button>
    </div> -->

    <div *ngIf="selectedPartyType" (click)="openOpeningBalanceCanvas()" class="custom-div opening-balance">
      <div class="d-flex gap-2">
        <span class="f-16 mx-2"
          style="font-weight: 400; color: #898da3; margin-inline: 8px">{{authService.session.company.currency ||
          '₹'}}</span>
        <div class="d-flex flex-column">
          <span class="f-12 disabled-font">Opening Balance</span>
          <span class="f-14">{{companyUser?.opening_balance | dynamicNumber: {currency:1} }}</span>
        </div>
      </div>

      <div class="d-flex gap-2">
        <span *ngIf="companyUser?.opening_balance>0" class="f-14 disabled-font m-0">Party will pay</span>
        <span *ngIf="companyUser?.opening_balance === 0" class="f-14 disabled-font m-0">---</span>
        <span *ngIf="companyUser?.opening_balance<0" class="f-14 disabled-font m-0">Party will receive</span>
        <div>
          <ng-icon style="color: #898da3" name="featherChevronRight"></ng-icon>
        </div>
      </div>
    </div>


    <!-- 
    <div *ngIf="!openingbalance" class="txt-btn-wrap">
      <button (click)="openingbalance = true" class="text-btn f-12 disabled-font">+ Opening Balance</button>
    </div> -->

    <div *ngIf="selectedPartyType !== 'Client' && selectedPartyType" (click)="openBankBalanceCanvas()"
      class="custom-div bank-deets">
      <div class="d-flex gap-2">
        <svg style="margin-inline: 8px" width="20" height="20" viewBox="0 0 20 20" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M2.49988 17.5H17.4999" stroke="#898DA3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M2.49988 8.33301H17.4999" stroke="#898DA3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4.1665 5L9.99984 2.5L15.8332 5" stroke="#898DA3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3.33313 8.33301V17.4997" stroke="#898DA3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M16.6665 8.33301V17.4997" stroke="#898DA3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6.6665 11.667V14.167" stroke="#898DA3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M9.99988 11.667V14.167" stroke="#898DA3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M13.3331 11.667V14.167" stroke="#898DA3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <div class="d-flex flex-column">
          <span class="f-12 disabled-font">Bank Account</span>
          <span class="f-14">{{bankDetails.account_number?bankDetails.account_number:'--NA--'}}<span
              *ngIf="bankDetails.account_name">({{bankDetails.account_name}})</span></span>
        </div>
      </div>

      <div>
        <ng-icon style="color: #898da3" name="featherChevronRight"></ng-icon>
      </div>
    </div>

    <ng-container *ngIf="selectedPartyType && customFields.length">
      <div (click)="openCustomFieldsList()" style="height: fit-content !important; min-height:57px"
        class="custom-div custom-fields d-flex flex-column gap-2 w-100 cursor pb-2">
        <div class="d-flex align-items-center justify-content-between gap-2 w-100 py-2">
          <div class="d-flex gap-3">
            <ng-icon class="d-flex justify-content-center align-items-center" style="color: #898da3;"
              name="featherList"></ng-icon>
            <span class="f-14 fw-normal disabled-font"> Custom Fields
            </span>
          </div>
          <div class="icon-box">
            <ng-icon style="color: #898da3; margin-inline: 8px" name="featherChevronRight"></ng-icon>
          </div>

        </div>
        <div style="flex-wrap: wrap;" class="d-flex  align-items-start justify-content-start gap-2 w-100">
          <ng-container *ngFor="let customField of customFields">
            <div style="min-width:130px;" class="d-flex flex-column align-items-start">
              <span style="font-weight: 500;" class="f-12">{{customField?.name}}</span>
              <span *ngIf="customField.data_type !== 'date'" class="f-12 disabled-font">{{customField?.default_value
                ?customField?.default_value : '--'}}</span>
              <span *ngIf="customField.data_type === 'date' && customField?.default_value"
                class="f-12 disabled-font">{{customField?.default_value | date:'d MMM, yyyy'}}</span>
              <span *ngIf="customField.data_type === 'date' && !customField?.default_value"
                class="f-12 disabled-font">--</span>
            </div>
          </ng-container>
        </div>

      </div>
    </ng-container>
    <div class="custom-div" *ngIf="companyUser.id">
      <div ngbDropdown class="form-control" style="border: none; background: transparent;" autoClose="outside">
        <div ngbDropdownToggle class="d-flex justify-content-start align-items-center gap-2"
          (click)="selectingAddress ? selectingAddress = false : selectingAddress = true">
          <div class="fs-8 align-self-center">Your Addresses</div>
          <div><ng-icon name="featherChevronDown"></ng-icon></div>
        </div>

        <div ngbDropdownMenu class="custom-menu-address">
          <div ngbDropdownItem class="d-flex p-2 pointer dropdown-list" (click)="setPrimaryAddress(item);"
            *ngFor="let item of partyAddress">
            <div class="d-flex align-items-center justify-content-center" style="width: 10%;">
              <input type="radio" [checked]="item.primary === 1">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-start" style="width: 80%;">
              <div class="d-flex flex-column align-items-start">
                <span class="f-14">{{item?.address_title}}</span>
              </div>
              <span class="f-12">{{item?.address_line_1}}</span>
              <span *ngIf="item?.address_line_2" class="f-12">{{item?.address_line_2}}</span>
              <span *ngIf="item?.city" class="f-12">{{item?.city}}</span>
              <span *ngIf="item?.state" class="f-12">{{item?.state}}</span>
              <span *ngIf="item?.monkey_patch_country_config?.country_name"
                class="f-12">{{item?.monkey_patch_country_config?.country_name}}</span>
              <span *ngIf="item?.postal_code" class="f-12">{{item?.postal_code}}</span>
            </div>

            <!-- Nested dropdown for more options -->
            <div ngbDropdown container="body" (click)="$event.stopPropagation()"
              class="d-flex align-items-center justify-content-center" style="width: 10%;">
              <button ngbDropdownToggle (click)="$event.stopPropagation()" class="btn p-0" type="button">
                <div>
                  <ng-icon name="featherMoreVertical"></ng-icon>
                </div>
              </button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem (click)="$event.stopPropagation(); openAddAddress(item)" style="color: #000;"
                  type="button" class="btn text-btn f-12">Edit</button>
                <button ngbDropdownItem (click)="$event.stopPropagation(); deleteAddress(item)" style="color: #000;"
                  type="button" class="btn text-btn f-12">Delete</button>
              </div>
            </div>
          </div>

          <div *ngIf="partyAddress.length == 0" ngbDropdownItem
            class="d-flex justify-content-center align-items-center">
            <span class="f-12 disabled-font">No Addresses found</span>
          </div>
        </div>
      </div>


      <div>
        <span class="f-12 pointer" style="color: #5F44CE;"
          (click)="selectingAddress = false; openAddAddress()">+Add</span>
      </div>
    </div>
    <!-- <div class="custom-div" *ngIf="companyUser.id">
      <label>Party ID</label>
      <span class="f-14">{{companyUser.party_id || '--Na--'}}</span>
      <div (click)="openPartyPrefixSequence()">
        <ng-icon name="featherEdit"></ng-icon>
      </div>
    </div> -->

    <div (click)="openCustomSequenceModal()" class="custom-div d-flex align-items-center gap-2 pointer">
      <label for="party-id">Party ID</label>
      <span class="f-14">{{companyUser.prefix}}{{companyUser.sequence }}</span>
      <button style="border: none;" class="btn icon-btn">
        <ng-icon name="featherEdit"></ng-icon>
      </button>
    </div>


    <!-- <div *ngIf="!bankDetails" class="txt-btn-wrap">
      <button (click)="bankDetails = true" class="text-btn f-12 disabled-font">+ Bank Details</button>
    </div> -->
  </div>
</div>