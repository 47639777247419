import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Company, Project, Transaction } from "src/app/interfaces/interfaces";
import { AuthService } from "src/app/core/services/auth.service";
import { INVOICE_FEATURE_TYPE } from 'src/app/enum/invoice_feature_details';
import { PaymentStatusType } from 'src/app/enum/paymentStatusType';
import { FEATURE_TYPE } from 'src/app/enum/transaction_feature_enums';
interface TransactionCard {
  date: Date,
  dateCardClass: string,
  dateCardImg: string,
  nameTitle: string,
  paymentType: string,
  remark?: string,
  paymentSubCategory?: string,
  paymentTypeStatus?: string,
  amount: number,
  paymentStatus?: string,
  project_name?: string
}
@Component({
  selector: 'app-list-transaction',
  templateUrl: './list-transaction.component.html',
  styleUrls: ['./list-transaction.component.css']
})
export class ListTransactionComponent {
  @Input() parentTransaction: Transaction;
  @Input() fromPage?: string;
  public transaction = {} as TransactionCard;
  public company = {} as Company;
  public project = {} as Project;
  constructor(
    public authService: AuthService
  ) {
    this.company = this.authService.session.company as Company || {};
    this.project = this.authService.session.project as Project;
  }
  ngOnInit() {
    let dateCard_class: string;
    let dateCard_image: string;
    let name_title: string;
    let payment_type: string;
    let finalAmount: number;
    let remark: string | undefined;
    let payment_status: string;

    if (this.parentTransaction.feature_type === FEATURE_TYPE.CASHBOOK) {
     
      finalAmount = this.parentTransaction.monkey_patch_cashbooktransaction.amount;
      remark = this.parentTransaction.monkey_patch_cashbooktransaction?.remark;
      payment_status = this.parentTransaction.monkey_patch_cashbooktransaction.mode
        ? this.parentTransaction.monkey_patch_cashbooktransaction.mode
        : null;
      if (this.parentTransaction?.monkey_patch_cashbooktransaction?.receiver_wallet_id === this.company.monkey_patch_wallet?.id) {
        dateCard_class = 'payment-in'
        dateCard_image = '../../../../assets/svg/payment-in.svg'
        name_title = this.parentTransaction.monkey_patch_party_company_user?.name
        payment_type = 'Payment In'
      } else if (this.parentTransaction?.monkey_patch_cashbooktransaction?.sender_wallet_id === this.company.monkey_patch_wallet?.id) {
        dateCard_class = 'payment-out'
        dateCard_image = '../../../../assets/svg/payment-out.svg'
        name_title = this.parentTransaction.monkey_patch_party_company_user?.name
        payment_type = 'Payment Out'
      } else if (this.parentTransaction?.monkey_patch_cashbooktransaction?.receiver_wallet_id !== this.company.monkey_patch_wallet?.id &&
        this.parentTransaction?.monkey_patch_cashbooktransaction?.sender_wallet_id !== this.company.monkey_patch_wallet?.id
      ) {
        name_title = `${this.parentTransaction.monkey_patch_cashbooktransaction.monkey_patch_sender_comapny_user?.name} > ${this.parentTransaction.monkey_patch_cashbooktransaction.monkey_patch_receiver_comapny_user?.name}`
        dateCard_class = "staff-payment"
        dateCard_image = "../../../../assets/svg/payment-internal.svg"
        payment_type = "Party Payment"
      }
    
    } else if (this.parentTransaction.feature_type === FEATURE_TYPE.INVOICE) {
      remark = this.parentTransaction.monkey_patch_invoice_feature?.remark;
      finalAmount = this.parentTransaction.invoice_feature_type == INVOICE_FEATURE_TYPE.CUSTOMER_INVOICE ? this.parentTransaction.monkey_patch_invoice_feature.total_amount : this.parentTransaction.monkey_patch_invoice.total_payable;
      payment_status = this.parentTransaction.monkey_patch_invoice.status == PaymentStatusType.Paid
        ? "Paid"
        : (this.parentTransaction.monkey_patch_invoice.status == PaymentStatusType.Partiallypaid ? `${PaymentStatusType.Unpaid} ` + (Number(this.parentTransaction.monkey_patch_invoice.total_payable - this.parentTransaction.monkey_patch_invoice.paid_amount).toFixed(this.authService.session.country.decimal_digit)) : PaymentStatusType.Unpaid);
      name_title = this.parentTransaction.monkey_patch_party_company_user?.name;
      if (this.parentTransaction?.invoice_feature_type === INVOICE_FEATURE_TYPE.PARTY_EARNING) {
        dateCard_class = "payment-material"
        dateCard_image = "../../../../assets/svg/payment-partyearing.svg"
        payment_type = "Other Expense"
      } else if (this.parentTransaction?.invoice_feature_type === INVOICE_FEATURE_TYPE.REIMBURSEMENT) {
        dateCard_class = "payment-material"
        dateCard_image = "../../../../assets/svg/payment-partyearing.svg"
        payment_type = "Site Expense"
      } else if (this.parentTransaction?.invoice_feature_type === INVOICE_FEATURE_TYPE.MATERIAL) {
        dateCard_class = "payment-material"
        dateCard_image = '../../../../assets/svg/payment-material.svg'
        payment_type = 'Material Purchased'
      } else if (this.parentTransaction?.invoice_feature_type === INVOICE_FEATURE_TYPE.MATERIAL_RETURN) {
        dateCard_class = "payment-material"
        dateCard_image = '../../../../assets/svg/payment-material-return.svg'
        payment_type = "Material Return"
      } else if (this.parentTransaction?.invoice_feature_type === INVOICE_FEATURE_TYPE.CUSTOMER_INVOICE) {
        dateCard_class = "sales-invoice"
        dateCard_image = '../../../../assets/svg/invoice.svg'
        payment_type = 'Sales Invoice'
      } else if (this.parentTransaction?.invoice_feature_type === INVOICE_FEATURE_TYPE.SUBCON_EXPENSE) {
        dateCard_class = "subcon-expense"
        dateCard_image = '../../../../assets/svg/subcon-expense.svg'
        payment_type = 'Subcon Expense'
      } else if (this.parentTransaction?.invoice_feature_type === INVOICE_FEATURE_TYPE.DEBIT_NOTE) {
        dateCard_class = "payment-material"
        dateCard_image = "../../../../assets/svg/payment-partyearing.svg"
        payment_type = 'Debit Note'
        remark = this.parentTransaction.monkey_patch_invoice_feature?.notes;
      }else if(this.parentTransaction?.invoice_feature_type === INVOICE_FEATURE_TYPE.MATERIAL_SALE){
        dateCard_class = "payment-material"
        dateCard_image = '../../../../assets/svg/payment-material-return.svg'
        payment_type = "Material Sales"
      }
    } else if (this.parentTransaction.feature_type == INVOICE_FEATURE_TYPE.MATERIAL_TRANSFER) {
      remark = this.parentTransaction.monkey_patch_materialtransfer?.remark;
      finalAmount = this.parentTransaction.monkey_patch_materialtransfer.total_payable;
      if (this.project?.id) {
        if (this.parentTransaction?.monkey_patch_materialtransfer.sender_project_id === this.project.id) {
          name_title = this.parentTransaction?.monkey_patch_materialtransfer.monkey_patch_receiver_project.name || '--';
          dateCard_class = "material-transfer-out"
          dateCard_image = "../../../../assets/svg/material-transfer-out.svg"
          payment_type = "Transfer Out"
        } else if (this.parentTransaction?.monkey_patch_materialtransfer.receiver_project_id === this.project.id) {
          name_title = this.parentTransaction?.monkey_patch_materialtransfer.monkey_patch_sender_project.name || '--';
          dateCard_class = "material-transfer-in"
          dateCard_image = "../../../../assets/svg/material-transfer-in.svg"
          payment_type = "Transfer In"
        }
      } else {
          name_title = this.parentTransaction?.monkey_patch_materialtransfer.monkey_patch_sender_project.name
          +' - '+this.parentTransaction?.monkey_patch_materialtransfer.monkey_patch_receiver_project.name || '--';
          dateCard_class = "material-transfer-in"
          dateCard_image = "../../../../assets/svg/material-transfer-in.svg"
          payment_type = "Material Transfer"
      }
    }

    this.transaction = {
      date: this.parentTransaction.transaction_date,
      dateCardClass: dateCard_class,
      dateCardImg: dateCard_image,
      nameTitle: name_title,
      paymentType: payment_type,
      remark: remark,
      paymentSubCategory: this.parentTransaction.monkey_patch_sub_category?.text_en ? this.parentTransaction.monkey_patch_sub_category?.text_en : "-",
      paymentTypeStatus: this.parentTransaction.monkey_patch_cashbooktransaction.category ? this.parentTransaction.monkey_patch_cashbooktransaction.category : "-",
      amount: finalAmount,
      paymentStatus: payment_status,
      project_name: this.parentTransaction.monkey_patch_project?.name || '-'
    };
  }
}
